html,
body {
  margin: 0;
  font-family: "Outfit", sans-serif;
  font-display: optional;
  font-size: 1rem;
  background-color: #fff;
}

.slick-slide div {
  outline: none;
}

.flexGrow {
  flex-grow: 1;
}

img {
  /* No select (copy) */
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  /* No drag */
  -webkit-user-drag: none;
}
